import React from "react"
import Uninterrupted from "../images/logo.uninterrupted.svg"
import Dhl from "../images/logo.dhl.svg"
import Crrf from "../images/logo.crrf.png"
import Emblem from "../images/emblem.png"
import Cmf from "../images/logo.cmf.png"
import { Link } from "gatsby"
import Social from "../components/social"

export default class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="o-wrapper o-content o-container u-text-center o-content-container">
                    <img src={Emblem} alt="Black Ice" />
                    <div className="h2">{this.props.global.partnerTitle}</div>
                    <div className="o-child-spacer u-flex-center u-flex-wrap">
                        <Link className="c-logo c-logo--xlarge" to="https://www.dhl.com/" target="_blank" rel="noreferrer">
                            <img src={Dhl} alt="DHL" />
                        </Link>
                        <Link className="c-logo c-logo--xlarge" to="https://cmf-fmc.ca/" target="_blank" rel="noreferrer">
                            <img src={Cmf} alt="Canada Media Fund" />
                        </Link>
                        <Link className="c-logo c-logo--xlarge" to="https://www.crrf-fcrr.ca/" target="_blank" rel="noreferrer">
                            <img src={Crrf} alt="The Canadian Race Relations Foundation" />
                        </Link>
                    </div>
                </div>
                <footer className="c-footer o-content u-text-center">
                    <div className="o-wrapper o-container u-flex-center u-flex-wrap">
                        <div className="c-footer__logo">
                            <a className="c-logo u-center" href="https://canada.uninterrupted.com">
                                <img src={Uninterrupted} alt={this.props.global.title} />
                            </a>
                        </div>
                        <Social type="footer"></Social>
                        <div className="u-text-right">
                            {this.props.global.footerNavigation.map((item, index) =>
                                <>
                                    <Link key={index} className="c-footer__link" to={item.link}>{item.title}</Link>
                                    {index < this.props.global.footerNavigation.length - 1 ? " | " : null}
                                </>
                            )}
                        </div>
                    </div>
                </footer>
            </>
        );
    }
};