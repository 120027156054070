import React from "react"
import BlackIce from "../images/emblem.png"
import Uninterrupted from "../images/logo.uninterrupted.dark.svg"
import { Link } from "gatsby"
import Tsn from "../images/logo.tsnwhite.svg"
import Crave from "../images/logo.cravewhite.svg"
import Scotiabank from "../images/logo.scotiabank.svg"

export default class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hamburgerOpen: false
        };
        this.toggleHamburger = this.toggleHamburger.bind(this);
    }

    toggleHamburger() {
        this.setState({ hamburgerOpen: !this.state.hamburgerOpen });
    }

    render() {
        return (
            <>
                <header className="c-header o-content-container o-wrapper o-container">
                    <a className="c-logo c-logo--small c-logo--fixed u-hide u-hide--mobile" href="https://canada.uninterrupted.com">
                        <img src={Uninterrupted} alt={this.props.global.title} />
                    </a>
                    <div className="c-header__top u-flex-center">
                        <div className="o-child-spacer o-child-spacer--small u-flex-grow">
                            <Link className="c-logo c-logo--small u-hide u-hide--desktop" to="/home">
                                <img src={BlackIce} alt={this.props.global.title} />
                            </Link>
                        </div>
                        <div role="button" className={`c-header__hamburger ${this.state.hamburgerOpen ? 'c-header__hamburger--open' : ''}`} onClick={this.toggleHamburger} onKeyPress={this.toggleHamburger} tabIndex="0">
                            <span></span>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <Link className="c-logo u-center u-hide u-hide--mobile" to="/home">
                        <img src={BlackIce} alt={this.props.global.title} />
                    </Link>
                    <div className="u-hide u-hide--mobile u-text-center u-text-white o-content-container">
                        <div className="o-child-spacer o-child-spacer--divider u-flex-center u-flex-wrap">
                            <Link className="c-logo c-logo--large" to="https://www.tsn.ca/" target="_blank" rel="noreferrer">
                                <img src={Tsn} alt="TSN" />
                            </Link>
                            <Link className="c-logo c-logo--large" to="https://www.crave.ca/" target="_blank" rel="noreferrer">
                                <img src={Crave} alt="Crave" />
                            </Link>
                        </div>
                        <h2>Presented by</h2>
                        <div className="o-child-spacer u-flex-center u-flex-wrap">
                            <Link className="c-logo c-logo--xlarge" to="https://www.scotiabank.com/ca/en/personal.html" target="_blank" rel="noreferrer">
                                <img src={Scotiabank} alt="Scotiabank" />
                            </Link>
                        </div>
                    </div>
                </header>
                <nav className={`c-navigation o-wrapper o-container ${this.state.hamburgerOpen ? 'c-navigation--open' : ''}`}>
                    <ul className="c-navigation__items o-child-spacer">
                        {this.props.global.navigation.map((item, index) =>
                            <li key={index} className="c-navigation__item">
                                <Link to={item.link} activeClassName="c-navigation__link--active" className="c-navigation__link">{item.title}</Link>
                            </li>
                        )}
                    </ul>
                </nav>
            </>
        );
    }
};